@use "@angular/material" as mat;

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css";

// Trinta Theme start
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trinta-primary: mat.define-palette(mat.$indigo-palette);
$trinta-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$trinta-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trinta-theme: mat.define-light-theme(
  (
    color: (
      primary: $trinta-primary,
      accent: $trinta-accent,
      warn: $trinta-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trinta-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --fontFamily: "Poppins", sans-serif;
  --headingFontFamily: "Inter", sans-serif;
  --primaryColor: #3761ee;
  --dangerColor: #ee368c;
  --infoColor: #2db6f5;
  --warningColor: #ffb264;
  --successColor: #2ed47e;
  --darkColor: #262626;
  --blackColor: #5b5b98;
  --whiteColor: #ffffff;
  --bodyColor: #a9a9c8;
  --transition: 0.5s;
  --fontSize: 14px;
}

// _Variable
@import "./variable";

// Utilities CSS
@import "./utilities";

*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  letter-spacing: 0.15px;
  color: var(--darkColor);
  background-color: #f5f7fa;
  font: {
    weight: 400;
    size: var(--fontSize);
    family: var(--fontFamily);
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  color: var(--darkColor);
  letter-spacing: 0.18px;
  margin: {
    top: 0;
    bottom: 15px;
  }
  font: {
    family: var(--headingFontFamily);
    weight: 600;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
a {
  transition: var(--transition);
  color: var(--darkColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    text-decoration: none;
    color: var(--primaryColor);
  }
}
p {
  font-size: var(--fontSize);
  color: var(--bodyColor);
  line-height: 1.8;
  margin: {
    bottom: 15px;
    top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
img {
  max-width: 100%;
  height: auto;
}
.mb-25 {
  margin-bottom: 25px !important;
}
input {
  color: var(--blackColor);
  font: {
    weight: normal;
    size: var(--fontSize);
    family: var(--fontFamily);
  }
  &::placeholder {
    color: var(--bodyColor);
  }
}
:focus {
  outline: 0 !important;
}
.heading-font-family {
  font-family: var(--headingFontFamily) !important;
}

// Font Size
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}

// UI Kit CSS
@import "./ui-kit";

// Dark CSS
@import "./dark";

/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .mb-25 {
    margin-bottom: 20px !important;
  }
  input {
    font-size: 13px;
  }

  // Font Size
  .fs-14 {
    font-size: 13px !important;
  }
  .fs-15 {
    font-size: 14px !important;
  }
  .fs-16 {
    font-size: 15px !important;
  }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {
}

//Trinta Theme end

.overflow-x-hidden {
  overflow-x: hidden;
}

.ch-30 {
  overflow: hidden;
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.progress-icon-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  .iconActive {
    color: #00836c !important;
  }

  .fa-solid {
    font-size: 18px !important;
  }
}
